<template>
  <v-app>
    <div class="px-12 py-2" id="hero">
      <v-row justify="start" align="center">
        <v-col cols="12">
          <p class="font-weight-bold h1">
            Register for a health plan
          </p>
          <p class="h4">Choose a Plan</p>
          <p class="text-muted lead h6 mb-0 mt-0">Choose an option below to get started.</p>
        </v-col>
        <v-col cols="6" class="mb-2">
          <v-row>
            <v-col
              cols="12"
              md="6"
              v-for="(plan, index) in plans"
              v-bind:key="index"
            >
              <v-hover v-slot="{ hover }" close-delay="100">
                <router-link :to="plan.url" append>
                  <v-card
                    class="mx-auto h-100"
                    outlined
                    :color="hover ? 'grey lighten-2' : 'white'"
                    :elevation="hover ? 10 : 2"
                  >
                    <v-card-title>
                      <small class="font-weight-bold">{{ plan.name }}</small>
                    </v-card-title>
                    <v-card-subtitle>
                      <small style="font-size: 0.8rem">{{ plan.description }}</small>
                    </v-card-subtitle>
                  </v-card>
                </router-link>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="text-left py-0">
          <small style="font-size: 0.9rem">
            If you don't fall under any of the above categories,
            <br />click on
            <router-link to="#" class="font-weight-bold">
              <strong>Others</strong>
            </router-link>
          </small>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      plans: [
        {
          name: "Informal Health Plan",
          url: "informal",
          description:
            "Accessible to all residents not covered by other health plans",
        },
        {
          name: "Formal Health Plan",
          url: "formal",
          description:
            "Public sector employees and organized private sector employees",
        },
        {
          name: "Private Health Plan",
          url: "private",
          description:
            "Variety of packages providing extra healthcare services",
        },
        {
          name: "Equity Health Plan",
          url: "equity",
          description:
            "Under five, pregnant women, elderly, physically & mentally challenged & internally Displaced",
        },
      ],
    };
  },
};
</script>

<style scoped>
#hero {
  background: url("~@/assets/images/svg/form-background.svg") no-repeat;
  background-size: 800px;
  background-position: right 85%;
}

a:hover {
  text-decoration: none;
}
</style>
